@media (max-width: 768px) {
  .cr-modal.modal-regulamento {
    .cr-modal-header {
      padding: 0;
    }

    ul {
      padding: 15px;
    }

    img.banner-regulamento {
      width: 100%;
    }

    &.modal-black {
      .cr-modal-header {
        border-bottom: 1px solid #353535;
      }

      .cr-modal-footer {
        border-top: 1px solid #353535;
      }

      .cr-modal-dialog {
        background-color: #282828;
        color: white;
      }
    }
  }
}

@media (min-width: 769px) {
  .cr-modal.modal-regulamento {
    .cr-modal-header {
      border-bottom: 1px solid #353535;
      padding: 0;
    }

    img.banner-regulamento {
      width: 100%;
      object-fit: none;
    }

    &.modal-black {
      .cr-modal-footer {
        border-top: 1px solid #353535;
      }

      .cr-modal-body {
        background-color: #282828;
        color: white;
      }
    }
  }
}