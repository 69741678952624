@import '../../_variables';
@import '../../element/_footer';
@import '../../element/_icons-mixins';
@import '../../element/_modal-promo';
@import '../../page/hotsite/black-friday';

//<editor-fold desc="FRAMES">
.frame {
  background-color: @color-default-1;
  border-radius: @radius-default;
  padding: 1rem;
}

.frame-outline {
  background-color: transparent;
  border-radius: @radius-default;
  padding: 1rem;
  border: 2px solid @color-default-2;
}

//</editor-fold>
.buyButton {
  border-radius: 1rem;
}

//<editor-fold desc="Buy Button">

//</editor-fold>

// <editor-fold desc="ICONS">

// social
.icon-instagram();
.icon-facebook();
.icon-twitter();
.icon-youtube();
.icon-tiktok();
.icon-pinterest();
.icon-spotify();
.icon-user();
.icon-wallet();
.icon-email();

// history
.icon-register();

// </editor-fold>

// <editor-fold desc="ALERTS">

[class*='alert-'] {
  font-size: 1.2rem;

  // todo remover
  button.close {
    color: white;
  }

  .icon-cr.icon-close {
    display: block;
    float: right;
    height: 1.8rem;
    width: 1.8rem;
    opacity: .7;
    cursor: pointer;
  }
}

// </editor-fold>

// <editor-fold desc="ANIMATIONS">
@keyframes openDrawer {
  0% {
    display: block;
    transform: translateX(-100%);
    box-shadow: none;
  }
  100% {
    display: block;
    transform: translateX(0);
    box-shadow: @default-shadow;
  }
}

@keyframes closeDrawer {
  0% {
    display: block;
    transform: translateX(0);
    box-shadow: @default-shadow;
  }
  100% {
    display: block;
    transform: translateX(-100%);
    box-shadow: none;
  }
}

@keyframes showOverlay {
  0% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: .3;
  }
}

@keyframes hideOverlay {
  0% {
    display: block;
    opacity: .3;
  }
  100% {
    display: block;
    opacity: 0;
  }
}

// </editor-fold>

//<editor-fold desc="DIVIDERS">

.spaced-dots {
  background-image: linear-gradient(to right, @color-default-4 20%, transparent 0%);
  background-position: bottom;
  background-size: 5px 1px;
  background-repeat: repeat-x;
  height: 3px;
}

//</editor-fold>
