@import '../../_variables';

#black-friday-23{

  h1 {
    font-family: "Bricolage Grotesque", sans-serif !important;
    font-size: 1.5rem;
    font-weight: 700;
  }

  @media (@min-screen-sm) {
    h1 {
      font-size: 2rem;
    }
  }


  img {
    border-radius: 12px;
  }

  .countdown-bf{
    background: url("https://uma-penca.imgix.net/pages/black-friday/fundo-countdown.png?auto=compress");
    max-width: 1920px;
    margin: 0 auto;
    height: 60px;
  }
  .imgs-countdown{
    width: 890px;
  }
  .img-faltam{
    float: left; margin-top: 5px;
  }
  .img-inicio{
    margin-top: 5px;
  }
  .number-count{
    color: #f22a63; font-weight:  700; font-size: 42px;    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-top: 0;
    float: left;
  }
  .button-promo{
    background: url("https://uma-penca.imgix.net/pages/black-friday-2024/bnr_inferior_fundo_01_desktop_BF_240924.png?auto=compress,format&q=70");
    max-width: 1920px;
    margin: 0 auto;
  }
  .button-guia{
    background: url("https://uma-penca.imgix.net/pages/black-friday-2024/bnr_inferior_fundo_02_desktop_BF_240924.png?auto=compress,format&q=70");
    max-width: 1920px;
    margin: 0 auto;
  }
  @media (max-width:890px) {
    .imgs-countdown{
      width: 590px;
    }
    .img-faltam{
      width: 80px;
    }
    .img-inicio{
      width: 364px;
    }
    .number-count{
      font-size: 30px;
    }
    .countdown-bf{
      height: 45px
    }
  }
  @media (max-width:500px) {
    .countdown-bf{
      background: none;
      height: auto;
      .content{
        margin: 0;
        max-width: 100%;
        padding: 0;
        .img-responsive{
          padding-top: 0 !important;
        }
      }
    }

  }

  .countdown-align {
    display: flex;
    justify-content: center;
    align-items: center;

    @media(@max-screen-xs){
      flex-direction: column;

      div {
        background-color: #f6f5f1;
        width: 100% !important;
        display: flex;
        justify-content: center;
      }
    }
  }
}